import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";
import "./App.scss";
import routes from "./constants/routes";
import Home from "./containers/Home";
import Search from "./containers/Search";
import WidgetOnly from "./containers/WidgetOnly";
import NotFoundPage from "./containers/NotFoundPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TechFooter from "./components/TechFooter";
import { setCurrency, setDirection, setLanguage, setWhiteLabel } from "./redux/ui/action";
import { RTL_DIRECTION } from "./constants/default";
import { useTranslation } from "react-i18next";
import * as queryString from "query-string";
import {useLocation} from "react-router";
// import HelloScreen from "./components/HelloScreen";

function App({ dispatch, dir, /*lng,*/ currency }) {
  // console.log('creating app...')
  const { i18n } = useTranslation(); // eslint-disable-line
  const [cid, setCid] = useState(null);
  // console.log(`cid on App render: ${cid}`)

  const rtl = dir === "rtl";
  // if (lng === undefined) {
  //   const lng = i18n.language.substring(0, 2);
  // // }
  // dispatch(setDirection(RTL_DIRECTION[lng] ? "rtl" : "ltr"));
  // dispatch(setLanguage(lng));

  const location = useLocation();
  const params = queryString.parse(location.search);
  const { wl, cur } = params;

  if (wl) {
    dispatch(setWhiteLabel(wl.replace(/[^\u0590-\u05fea-zA-Z0-9 ]/g, '')));
  }

  // if (currency === undefined) {
  //   // set currency based on device locale. default to IL.
  //   const country = navigator.languages.length > 0 && navigator.languages[0].indexOf('-') > -1 ? navigator.languages[0].split('-')[1] : 'IL'
  //   const countryToCurrency = require('country-to-currency');
  //   currency = countryToCurrency[country]
  //   console.log(`setting initial currency to ${currency} based on country ${country} ...`)
  //   dispatch(setCurrency(currency))
  // }

  useEffect(() => {
    if (cur && currency !== cur) {
      console.log('setting currency from query string...') // should happen only on initial page load
      dispatch(setCurrency(cur))
    }

    // brought this from Search.js container to make it available also for homepage
    if (window.gtag) {
      window.gtag('get', 'G-SEK9X7V9L8', 'client_id', (client_id) => {
          console.log(`pulled gtag cid: ${client_id}`)
          setCid(client_id)
          // console.log(`cid on App useEffect: ${cid} (client_id: ${client_id})`)
        })
    } else {console.log('no gtag')}
  }, []);

  useEffect(() => {
    const lng = i18n.language.substring(0, 2);
    dispatch(setDirection(RTL_DIRECTION[lng] ? "rtl" : "ltr"));
  }, [i18n.language]); // runtime was complaining we re-render during render so we useEffect

  return (
    <Switch>
      <Route path={`${routes.LANGS}/${routes.WIDGET}`}>
        <WidgetOnly />
        <TechFooter enableLogRocket="false" />
      </Route>
      <Route>
        <div
          className={`main d-flex flex-column min-vh-100 ${
            rtl ? "text-right" : "text-left"
          }`}
          dir={rtl ? "rtl" : "ltr"}
        >
          <Header cid={cid} />
          <Switch>
            <Route path={`${routes.LANGS}/${routes.SEARCH}`} render={(props) => <Search {...props} cid={cid} />} />
            <Route exact path={`${routes.LANGS}`} render={(props) => <Home {...props} cid={cid} />} />
            <Route component={NotFoundPage} />
          </Switch>
          <Footer />
          <TechFooter enableLogRocket="true" />
          {/* <HelloScreen /> */}
        </div>
      </Route>
    </Switch>
  );
}

// export default App;

const mapStateToProps = ({ vendors, results, ui, currencies }) => ({
  dir: ui.direction,
  // lng: ui.language,
  currency: ui.currency,
});

export default connect(mapStateToProps)(App);
