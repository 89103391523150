import filter from "lodash/filter";

export const BACKEND_API_ENDPOINT = process.env.REACT_APP_BACKEND_URL
export const MAX_REQUEST_COUNT = 30;
export const MAX_REQUEST_PER_SECOND = 1; // correct name would have been delay between checks
export const RTL_DIRECTION = {
  'en': false,
  'ar': true,
  'he': true,
};

export const currencies = ["ILS", "USD", "EUR", "AUD", "GBP"];

export const DEFAULT_FILTERS = { sortPrice: 'asc', insurance: ['basicInsurance', 'zeroExcess', 'excessRefundIncluded']};
export const SORT_PRICE = {
  title: 'sortBy',
  items: [
    {value: 'asc', title: 'priceLowToHigh'},
    {value: 'desc', title: 'priceHighToLow'},
  ]
};

export const FILTERS = {
  transmission: {
    field: 'spec.transmission',
    title: 'transmission',
    items: [
      {value: 'A', title: 'automatic'},
      {value: 'M', title: 'manual'},
    ]
  },
  carGroup: {
    filterFunction: (data, carGroup) => {
      const filterLogic = item => {
        if (item.group) {
          const carGroupRegex = carGroup.join ? carGroup.join("|") : carGroup
          return carGroupRegex.match(item.group[0])
        }
      }

      if (Array.isArray(data)){
        return filter(data, filterLogic)
      } else {
        return filterLogic(data)
      }
    },
    field: 'group',
    title: 'carGroup',
    items: [
      {value: 'M', title: 'mini'},
      {value: 'N', title: '$t(mini) $t(elite)'},
      {value: 'E', title: 'economy'},
      {value: 'H', title: '$t(economy) $t(elite)'},
      {value: 'C', title: 'compact'},
      {value: 'D', title: '$t(compact) $t(elite)'},
      {value: 'I', title: 'intermediate'},
      {value: 'J', title: '$t(intermediate) $t(elite)'},
      {value: 'S', title: 'standard'},
      {value: 'R', title: '$t(standard) $t(elite)'},
      {value: 'F', title: 'fullSize'},
      {value: 'G', title: '$t(fullSize) $t(elite)'},
      {value: 'P', title: 'premium'},
      {value: 'U', title: '$t(premium) $t(elite)'},
      {value: 'L', title: 'luxury'},
      {value: 'W', title: '$t(luxury) $t(elite)'},
      {value: 'X', title: 'special'},
    ]
  },
  carType: {
    filterFunction: (data, carType) => {
      const filterLogic = item => {
        if (item.group) {
          const carTypeRegex = carType.join ? carType.join("|") : carType
          return carTypeRegex.match(item.group[1])
        }
      }

      if (Array.isArray(data)){
        return filter(data, filterLogic)
      } else {
        return filterLogic(data)
      }
    },
    title: 'carType',
    items: [
      {value: 'F|G', title: 'suv'},
      {value: 'W', title: 'estate'},
      {value: 'V', title: 'van'},
      {value: 'S', title: 'sport'},
      {value: 'T', title: 'convertible'},
    ]
  },
  features: {
    filterFunction: (data, features) => {
      const filterLogic = item => {
        let isMatch = false
        if (features.includes("is4x4") && item.spec.is4x4) {
          isMatch = true
        }
        if (features.includes("isHybrid") && item.spec.isHybrid) {
          isMatch = true
        }
        if (features.includes("isElectric") && item.spec.isElectric) {
          isMatch = true
        }
        return isMatch
      }

      if (Array.isArray(data)){
        return filter(data, filterLogic)
      } else {
        return filterLogic(data)
      }
    },
    title: 'features',
    items: [
      { value: 'isElectric', title: 'electric' },
      { value: 'isHybrid', title: 'hybrid' },
      { value: 'is4x4', title: '4x4' },
    ]
  },
  payNow: {
    filterFunction: (data, payNow) => {
      const filterLogic = item => {
        let isMatch = false
        if (payNow.includes("zeroPrepayment") && item.payment.payNow === 0) {
          isMatch = true
        }
        if (payNow.includes("partialPrepayment") && (item.payment.payNow < item.payment.total) ) { 
          isMatch = true
        }
        if (payNow.includes("fullPrepayment") && (item.payment.payNow === item.payment.total) ) { // im unsure if we always set to numeric 0 or sometimes to "0"
          isMatch = true
        }
        return isMatch
      }

      if (Array.isArray(data)){
        return filter(data, filterLogic)
      } else {
        return filterLogic(data)
      }
    },
    title: 'payNow',
    items: [
      {value: 'zeroPrepayment', title: 'zeroPrepayment'},
      {value: 'partialPrepayment', title: 'partialPrepayment'},
      {value: 'fullPrepayment', title: 'fullPrepayment'},
    ]
  },
  // excess: {
  //   title: 'excess',
  //   items: [
  //     {value: 0, title: 'zeroExcess'},
  //   ]
  // },
  insurance: {
    filterFunction: (data, insurance) => {
      const filterLogic = item => {
        let isMatch = false
        if (insurance.includes("mandatoryCoverageNotIncluded") && item.terms.mandatoryCoverageNotIncluded === true) {
          isMatch = true
        }
        if (insurance.includes("basicInsurance") && (item.terms.basicCoverageIncluded === true && typeof(item.terms.excessRefundIncluded) === 'undefined' || item.terms.excessRefundIncluded === false) ) { 
          isMatch = true
        }
        if (insurance.includes("zeroExcess") && item.terms.excess == 0) { // im unsure if we always set to numeric 0 or sometimes to "0"
          isMatch = true
        }
        if (insurance.includes("excessRefundIncluded") && item.terms.excessRefundIncluded) {
          isMatch = true
        }
        return isMatch
      }

      if (Array.isArray(data)){
        return filter(data, filterLogic)
      } else {
        return filterLogic(data)
      }
    },
    title: 'insurance',
    items: [
      {value: 'basicInsurance', title: 'basicInsurance'},
      {value: 'zeroExcess', title: 'zeroExcess'},
      {value: 'excessRefundIncluded', title: 'excessRefundIncluded'},
      {value: 'mandatoryCoverageNotIncluded', title: 'mandatoryCoverageNotIncluded'},
    ]
  },
};

export const AIRPORT_FILTERS = {
  deskLocation: {
    title: 'deskLocation',
    field: 'localSupplier.deskLocation',
    conditionField: 'isAirport',
    conditionValue: true,
  },
}

export const FILTERS_COLLECT = {
  mileage: {
    title: 'mileage',
    field: 'terms.kmLimit'
  },
  seats: {
    title: 'seats',
    field: 'spec.seats'
  },
  // excess: {
  //   title: 'excess',
  //   field: 'terms.excess',
  //   currency: true
  // },
  // deposit: {
  //   title: 'deposit',
  //   field: 'terms.deposit',
  //   currency: true
  // },
  localSupplier: {
    title: 'localSupplier',
    field: 'localSupplier.displayName'
  },
  cancellationTerms: {
    title: 'cancellationTerms',
    field: 'terms.cancellationTerms'
  }
};
export const LOGROCKET_KEY = "2bps2g/search";
export const CLARITY_KEY = "i8eqao160w";