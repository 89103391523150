import React, { useState } from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import {useTranslation} from "react-i18next";
// import { xor, map, sortBy, uniqBy } from 'lodash';
import xor from 'lodash/xor';
import map from 'lodash/map';
// import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import { SORT_PRICE, FILTERS, FILTERS_COLLECT, AIRPORT_FILTERS } from '../../constants/default';
import {isMobile} from "react-device-detect";
import CheckboxList from './CheckboxList';
import './styles.scss';
// import { intersection, orderBy, size, uniq } from 'lodash';
import intersection from 'lodash/intersection';
import orderBy from 'lodash/orderBy';
import size from 'lodash/size';
import uniq from 'lodash/uniq';


const FilterWidget = ({ dir, data = [], values, onChange, onClear, isAirport, locationSlug }) => {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  //  const locale = LOCALES[i18n.language.substring(0,2)];
  const locale = i18n.language;

  const [sortKey, setSortKey] = useState(isMobile ? '1' : '0');
  const [filterKey, setFilterKey] = useState(isMobile ? '1' : '0');
  const { sortPrice } = values;

  const changeSort = value => () => {
    onChange({ sortPrice: value });
  };

  const changeParam = param => value => () => {
    // console.log('changeParam toggled', param, value)
    // console.log('old val', values[param])
    // the values array may contain querystring filter params. in cases where there is a single querystring param with the same key
    // the value is a string and not the expected array. So we make it array if it isn't.
    let oldValue = Array.isArray(values[param]) ? values[param] : [values[param]]
    // if the last step created an array with an undefined, lets filter it out because xor won't filter it in oppose to passing a straight undefined
    oldValue = oldValue.filter(item => item)
    const newValue = xor(oldValue, [value]);
    // console.log('new val', newValue)
    onChange({ [param]: newValue });
  };

  const toggleSort = () => {
    setSortKey(sortKey === '1' ? '0' : '1');
  };

  const toggleFilter = () => {
    setFilterKey(filterKey === '1' ? '0' : '1');
  };

  let filters = FILTERS_COLLECT
  if (isAirport) {
    // console.log('adding airport filters')
    Object.assign(filters, AIRPORT_FILTERS)
  }

  // generating the dynamic filters based on predefined fields and dynamic values we have in our results
  const collected = {}
  map(filters, (param, key) => {
    const arr = param.field.split('.')
    const mapped = map(data, item => {
      const ret = {};
      if (item[arr[0]] && typeof(item[arr[0]][arr[1]]) !== 'undefined') {
        ret.value = item[arr[0]][arr[1]];
        ret.title = item[arr[0]][arr[1]];
        if (param.currency && item[arr[0]]["currency"]) {
          ret.value = ret.value.toLocaleString(locale, { style: 'currency', currency: item[arr[0]]["currency"] })
          ret.title = ret.title.toLocaleString(locale, { style: 'currency', currency: item[arr[0]]["currency"] })
        }
      }

      return ret;
    });

    // qnd filter empty items (due to getting unexpected data back from API, i.e. ofran with array with null item)
    let filtered = mapped.filter(item => typeof (item.value) != 'undefined' && typeof (item.title) != 'undefined')
    filtered = mapped.filter(item => item.value != null && item.title != null)
    const uniqs = uniqBy(filtered, 'title');
    // const sorted = sortBy(uniqs, 'value');
    const sorted = orderBy(uniqs, [uniq => {
      if (uniq && uniq.value) {
        return typeof(uniq.value) === 'string' ? uniq.value.toLowerCase() : uniq.value
      } else {
        console.log(`should not happen got a value of ${uniq.value} for filter ${key}`)
        // debugger
        return null
      }
      
    }]);
    const items = map(sorted, value => ({ value: value.value, title: value.title }));
    collected[key] = {
      // key,
      title: param.title,
      field: param.field,
      items: items
    }
  });

  // getting matches and counts for each dynamic filter <-- needs to only be done once on results load
  const allFilters = {}
  // doing object.keys to preserve the order of filters or else ...exploding FILTERS and collected into allFilters results with random order of filters
  Object.keys(FILTERS).map(key => {
    allFilters[key] = FILTERS[key]
  })
  Object.keys(collected).map(key => {
    allFilters[key] = collected[key]
  })
  map(allFilters, (param, key) => {
    // for each filter, we need to iterate all possible values and filter the unfiltered results, to get full counts and matches
    param.items = map(param.items, item => {
      const matchedOffers = data.filter(offer => {
        if (param.filterFunction) {
          if (size(allFilters[param.title])) {
            return param.filterFunction(offer, item.value)
          } else {
            return true
          }
        }
        
        const arr = param?.field?.split('.')
        if (arr && arr.length === 1) {
          return offer[arr[0]] && typeof (offer[arr[0]]) !== 'undefined' && offer[arr[0]] === item.value
        } else if (arr && arr.length === 2) {
          return offer[arr[0]] && typeof (offer[arr[0]][arr[1]]) !== 'undefined' && offer[arr[0]][arr[1]] === item.value
        } else {
          console.log(`oh oh2 ${param.title}`)
          return true // false
        }
      })
      item.count = matchedOffers.length
      item.matchedOffers = matchedOffers
      return item
    })
    return param
  });

  let isAnyFilterSet = false
  map(values, (val, key) => {
    if (Array.isArray(val) && val.length > 0) {
      isAnyFilterSet = true
    }
  })
  // console.log(`isAnyFilterSet = ${isAnyFilterSet}`)

  // get dynamic counts after at least one filter is set
  // getting counts for each dynamic filter
  if (isAnyFilterSet) {
    map(allFilters, (param, key) => {
      param.currentlyFilteredOffersForGroup = []
      param.isUsed = values && values[param.title] && values[param.title].length > 0
      param.items.forEach(i => {
        if (!param.isUsed || (values && values[param.title] && values[param.title].indexOf(String(i.value)) > -1) ){
          // console.log(`adding matches of filter ${param.title} ${values[param.title]}=${i.value} - ${i.matchedOffers.length} items`)
          param.currentlyFilteredOffersForGroup.push(...i.matchedOffers)
        }
      })
      param.currentlyFilteredOffersForGroup = uniq(param.currentlyFilteredOffersForGroup)
      // console.log(`we should have in total ${param.currentlyFilteredOffersForGroup.length} offers for filters in group ${param.title}`)
    });

    map(allFilters, (param, key) => {
      // console.log(`setting counts for filter group ${param.title}...`)

      // for each filter, we need to iterate all possible values and filter the currently filtered shown results, to get counts for what is still shown
      let allOtherMatchedOffers = [...data]
      map(allFilters, (otherParam, otherKey) => {
        if (param.title !== otherParam.title && otherParam.isUsed) {
          if (allOtherMatchedOffers === null) {
            allOtherMatchedOffers = otherParam.currentlyFilteredOffersForGroup
          } else {
            allOtherMatchedOffers = intersection(allOtherMatchedOffers, otherParam.currentlyFilteredOffersForGroup)
          }
        }
      })

      param.items = map(param.items, item => {
        const matchedFilterOffers = intersection(item.matchedOffers, allOtherMatchedOffers)
        const finalMatchedOffers = uniq([...matchedFilterOffers])
        item.count = finalMatchedOffers.length
        return item
      })
      return param
    });
  }

  return (
    <div className="filter-section mb-3">
      <Row noGutters={true} className="mb-2">
        <Col xs={12} className="mb-2">
          <Accordion defaultActiveKey="-1" activeKey={sortKey} onSelect={toggleSort}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <svg className='mx-2' height="24" width="24" fill='#007bff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                {t('sortBy')}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {SORT_PRICE.items.map(item => (
                    <Form.Check
                      dir={dir}
                      key={item.value}
                      type="radio"
                      id={item.value}
                      name="priceOrder"
                      checked={sortPrice === item.value}
                      label={t(item.title)}
                      onChange={changeSort(item.value)}
                    />
                  ))}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col xs={12}>
          <Accordion defaultActiveKey="-1" activeKey={filterKey} onSelect={toggleFilter}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <svg className='mx-2' height="24" width="24" fill='#007bff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M3.9 54.9C10.5 40.9 24.5 32 40 32l432 0c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9 320 448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6l0-79.1L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"/></svg>
                {t('filter')}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="filter-content">
                  {/* {Object.keys(FILTERS).map(key => (
                    <CheckboxList
                      key={key}
                      name={key}
                      element={FILTERS[key]}
                      value={values[key]}
                      onChange={changeParam(key)}
                      // count={counts[key]}
                    />
                  ))} */}
                  {map(allFilters, item => (
                    <CheckboxList
                      key={item.title}
                      name={item.title}
                      element={item}
                      value={values[item.title]}
                      onChange={changeParam(item.title)}
                      locationSlug={locationSlug}
                      // count={counts[item.key]}
                    />
                  ))}
                  {/*<CheckboxList element={{title: '', items: mileageValues}} value={mileage} onChange={changeMileage} />*/}
                  {/* <Row>
                    <Col>
                      <Button variant="primary" block onClick={onClear}>{t('clearFilters')}</Button>
                    </Col>
                    <Col>
                      <Button variant="primary" block>{t('filter')}</Button>
                    </Col>
                  </Row> */}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Row noGutters={true}>
        <Col xs={12}>
          <Button variant="outline-primary" block onClick={onClear}>❌ {t('clearFilters')}</Button>
        </Col>
      </Row>
  </div>
  )
};

export default FilterWidget;
