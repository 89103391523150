import { combineReducers } from "redux";
import vendorsReducer from "./vendors/reducer";
import resultsReducer from "./results/reducer";
import uiReducer from "./ui/reducer";
import locationsReducer from "./locations/reducer";
import currenciesReducer from "./currencies/reducer"
import prefsReducer from './prefs/prefsSlice';          // using createSlice 🎉
import userReducer from "./user/userSlice";

export default combineReducers({
  vendors: vendorsReducer,
  results: resultsReducer,
  ui: uiReducer,
  locations: locationsReducer,
  currencies: currenciesReducer,
  prefs: prefsReducer,
  user: userReducer,
});
