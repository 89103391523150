import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { auth } from '../../auth/firebaseConfig';
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, signOut } from 'firebase/auth';
import { FcGoogle } from "react-icons/fc";
import { getCookie } from '../../utils/cookies';
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../redux/user/userSlice";

function LoginModal({ shouldShow, setModalVisible, afterLogin, cid }) {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const getAttributor = () => {
    const attributor = getCookie('attributor')
    const attributorExpiry = getCookie('attributor_expiry')
    return { attributor, attributorExpiry }
  }

  const handleClose = () => setModalVisible(false);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    try {
      const lng = i18n.language.substring(0, 2)
      // Set the language for the Google Sign-In popup (e.g., 'he' for Hebrew)
      provider.setCustomParameters({
        hl: lng // 'hl' is the parameter for setting language
      });
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();

      // Send token to backend for verification
      const res = await fetch(`${process.env.REACT_APP_USERS_BACKEND_URL}/api/auth/firebase`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: idToken,
          attributor: getAttributor(),
          externalClientId: cid,
        }),
      });

      const data = await res.json();
      if (data) {
        afterLogin(data)
      }
      handleClose();
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (isRegistering) {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(result.user);
        setEmailSent(true);
      } else {
        const result = await signInWithEmailAndPassword(auth, email, password);
        if (!result.user.emailVerified) {
          setError("Please verify your email before logging in.");
          return;
        }
        afterLogin(result.token)
        handleClose();
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout failed:", error)
    }
    dispatch(logoutUser());
    afterLogin(null)
  };

  return (
    <>
      <Modal show={shouldShow} onHide={handleClose} centered>
        <Modal.Body className="p-4">
          <Container>
            {user ? (
              <div className="text-center">
                {user.picture ? (
                  <img src={user.picture} alt="Profile" className="rounded-circle mb-3" width="100" />
                ) : (
                  <div className="rounded-circle text-white d-flex align-items-center justify-content-center" style={{ backgroundColor: "#87d6e4", width: "48px", height: "48px", fontSize: "30px", marginLeft: "auto", marginRight: "auto" }}>
                    {user.name ? user.name.charAt(0).toUpperCase() : user.email.charAt(0).toUpperCase()}
                  </div>
                )}
                <h5>{user.name || user.email}</h5>
                <p>{user.email}</p>
                <Button variant="danger" className="w-100 mt-3" onClick={handleLogout}>{t("logout")}</Button>
              </div>
            ) : (
              <>
                <h4 className="text-center mb-4">{isRegistering ? t("registerTitle") : t("loginTitle")}</h4>
                <Button variant="light" className="w-100 mb-3 border" onClick={handleGoogleLogin} disabled={loading}>
                  <FcGoogle size={22} className="me-2" /> {t("loginWithGoogle")}
                </Button>
                <div className="text-center text-muted my-3">{t("or")}</div>
                {error && <p className="text-danger text-center">{error}</p>}
                {emailSent && <p className="text-success text-center">Verification email sent. Please check your inbox.</p>}
                <Form onSubmit={handleEmailAuth}>
                  <Form.Group controlId="email">
                    <Form.Control
                      type="email"
                      placeholder={t("emailPlaceholder")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="password" className="mt-3">
                    <Form.Control
                      type="password"
                      placeholder={t("passwordPlaceholder")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" className="w-100 mt-3" type="submit" disabled={loading}>
                    {loading ? t("loading") : isRegistering ? t("register") : t("loginWithEmail")}
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <small>
                    {isRegistering ? t("alreadyHaveAccount") : t("noAccount")}
                    <span className="text-primary" style={{ cursor: "pointer" }} onClick={() => setIsRegistering(!isRegistering)}>
                      {isRegistering ? t("login") : t("register")}
                    </span>
                  </small>
                </div>
              </>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={handleClose}>{t("close")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginModal;