import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../LanguageSwitch';
import CurrencySwitch from '../CurrencySwitch';

function RedirectModal({dispatch, dir, seconds, setModalVisible, link, cid}) {
  const { t } = useTranslation();
  // console.log(`RedirectModal: ${shouldShow}`)
  const [countDown, setCountDown] = useState(seconds);
  useEffect(() => {
    if (countDown > 0) {
      const timer = setTimeout(() => setCountDown(countDown - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      // redirect
      if (window.gtag) {
        window.gtag('event', 'Redirect', {
          // 'event_category': 'Conversion',
          // 'event_label': supplier,
          // 'value': <value></value>
        })
        window.gtag('set', 'user_properties', { 'custom_client_id': cid })
        // console.log(link, cid);
      }
      
      setTimeout(() => window.open(link, '_self'), 500);
    }
  }, [countDown]);

  const handleClose = () => {
    setModalVisible(false);
  }
  // const handleShow = () => setModalVisible(true);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      // backdrop="static"
      keyboard={true}
      dir={dir}
      centered
      style={dir === "rtl" ? { textAlign: "right" } : {}}
    >
    <Modal.Header>
        <Modal.Title>{t("redirectingToPartner")}</Modal.Title>
    </Modal.Header>
      <Modal.Body>
        {t("redirectingIn", {seconds: countDown})}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={handleClose}>{t("close")}</Button>
    </Modal.Footer>
  </Modal>  )
}

const mapStateToProps = ({ ui }) => ({
  dir: ui.direction,
});

export default connect(mapStateToProps)(RedirectModal);