import React from 'react';
import './styles.scss';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useTranslation} from "react-i18next";
import { FILTERS } from "../../constants/default";
import Rating from './Rating';

const MobileItem = ({ dir, item = {}, onItemClicked, cid, isAirport, locationSlug }) => {
  const { t, i18n } = useTranslation();
  const rtl = dir === "rtl";
//  const locale = LOCALES[i18n.language.substring(0,2)];
  const locale = i18n.language;

  const trackOutbound = (e) => {
    const url = e.target.getAttribute("href");
    const supplier = e.target.getAttribute("data-supplier");
    if (window.gtag) {
      window.gtag('event', 'Outbound Click', {
        'event_category': 'Conversion',
        'event_label': supplier,
        // 'value': <value></value>
      })
      window.gtag('set', 'user_properties', { 'custom_client_id': cid })
      console.log(url, supplier);
    }
    
    if (onItemClicked) {
      onItemClicked()
    }
  }

  const trackClick = (label) => {
    if (window.gtag) {
      window.gtag('event', 'Click', {
        'event_category': 'Engagement',
        'event_label': label,
        // 'value': <value></value>
      })
    }
    console.log(label);
  }

  const acrissToCategory = (code) => {
    let found = false;
    if (code) {
      found = FILTERS.carGroup.items.find(item => {
        return (item.value.match(code[0]));
      });
    }
    
    if (found) {
      return found.title;
    } else {
      return "";
    }
  }

  const acrissToCarType = (code) => {
    let found = false;
    if (code) {
      found = FILTERS.carType.items.find(item => {
        return (item.value.match(code[1]));
      });
    }

    if (found) {
      return found.title;
    } else {
      return "";
    }
  }

  return (
  <Row noGutters={true} className="item-wrapper mb-1 mb-md-2 rounded">
    <Row className="w-100">
      <Col xs={12}>
        <h3 dir='ltr'>{item.model}</h3>
        <div key='orSimilarInGroup'>
          {item.isProperGroup ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-circle text-success goodAcriss" viewBox="0 0 16 16" data-group={item.group} data-model={item.model} data-category={acrissToCategory(item.group)}>
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
            </svg>
          ) : ""}
          {item.isGoodGroup && !item.isProperGroup ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-circle text-success infoAcriss" viewBox="0 0 16 16" data-group={item.group} data-model={item.model} data-category={acrissToCategory(item.group)} data-correct-group={item.correctGroup}>
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
            </svg>
          ) : ""}
          {!item.isGoodGroup && !item.isProperGroup && item.correctGroup ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-circle text-danger badAcriss" viewBox="0 0 16 16" data-group={item.group} data-model={item.model} data-category={acrissToCategory(item.group)} data-correct-group={item.correctGroup}>
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
            </svg>
          ) : ""}
          {/* qnd to promise we have a single space between the icon and the text */}
          {" " + t(acrissToCarType(item.group))} {t(acrissToCategory(item.group))} {t(item.spec.transmission)} ({item.group})
        </div>
        {item.image ? <img src={item.image} className="w-100" alt={item.model} /> : null}
      </Col>
    </Row>
    <Row className="w-100 mb-2">
      <Col xs={12} className="spec">
        {/* {Object.keys(item.spec).map(key => (
          <div key={key} className="col-6">
            <b>{t(key)}</b> {t(item.spec[key])}
          </div>
        ))} */}
          {item.spec.seats ? (
            <Row key='seats'>
            <Col xs={6}>
              <b>{t('seats')}</b>
            </Col>
            <Col xs={6}>
              {t(item.spec.seats)}
            </Col>
            </Row>
          ) : ''}
          {item.spec.maxLuggageCapacity && item.spec.maxLuggageCapacity !== '?' ? (
            <Row key='maxLuggageCapacity'>
            <Col xs={6}>
              <b>{t('maxLuggageCapacity')}</b>
            </Col>
            <Col xs={6}>
              {t(item.spec.maxLuggageCapacity)} {t('liters')}
            </Col>
            </Row>
          ) : ''}
          <Row key='kmLimit'>
            <Col xs={6}>
              <b>{t('kmLimit')}</b>
            </Col>
            <Col xs={6}>
              {t(item.terms.kmLimit)}
            </Col>
          </Row>
          <Row key='cancellationTerms'>
            <Col xs={6}>
              <b>{t('cancellationTerms')}</b>
            </Col>
            <Col xs={6}>
              {t(item.terms.cancellationTerms)}
            </Col>
          </Row>
          <Row key='localSupplier'>
            <Col xs={6}>
              <b>{t('localSupplier')}</b>
            </Col>
            <Col xs={6}>
              {item.localSupplier.logoUrl ? <img src={item.localSupplier.logoUrl} className="supplier-logo" alt={item.localSupplier.displayName} /> : item.localSupplier.displayName}
            </Col>
          </Row>
          {
            !item?.localSupplier?.googlePlace || typeof item?.localSupplier?.googlePlace === "undefined" ? ""
            :
            (
              <Row key='branchRating'>
              <Col xs={6}>
                <b>{t('branchRating')}</b>
              </Col>
              <Col xs={6}>
                <Rating localSupplier={item.localSupplier} locationSlug={locationSlug} />
              </Col>
              </Row>
            )
          }
          {isAirport ? <Row key='deskLocation'>
            <Col xs={6}>
              <b>{t('deskLocation')}</b>
            </Col>
            <Col xs={6}>
              {item.localSupplier.deskLocation ? t(item.localSupplier.deskLocation) + ' ' : ' - '}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-question-circle p-8 deskLocation" data-desk-location={item?.localSupplier?.deskLocation} data-location-slug={locationSlug} viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
              </svg>
            </Col>
          </Row>: ""}
          {item.terms.zeroExcess ? (
          <Row key='zeroExcess'>
            <Col xs={12}>
              <span className="badge badge-info zeroExcess">{t('zeroExcess')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.terms.tireAndGlassInsuranceIncluded ? (
          <Row key='tireAndGlassInsuranceIncluded'>
            <Col xs={12}>
              <span className="badge badge-info tireAndGlassInsuranceIncluded">{t('tireAndGlassInsuranceIncluded')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.premiumCustomerService ? (
          <Row key='premiumCustomerService'>
            <Col xs={12}>
              <span className="badge badge-danger">{t('premiumCustomerService')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.terms.additionalDriverIncluded ? (
          <Row key='additionalDriverIncluded'>
            <Col xs={12}>
              <span className="badge badge-success">{t('additionalDriverIncluded')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.terms.zeroPrepayment ? (
          <Row key='zeroPrepayment'>
            <Col xs={12}>
              <span className="badge badge-info">{t('zeroPrepayment')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.terms.excessRefundIncluded ? (
          <Row key='excessRefundIncluded'>
            <Col xs={12}>
              <span className="badge badge-success excessRefundIncluded">{t('excessRefundIncluded')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.terms.mandatoryCoverageNotIncluded ? (
          <Row key='mandatoryCoverageNotIncluded'>
            <Col xs={12}>
              <span className="badge badge-danger mandatoryCoverageNotIncluded">{t('mandatoryCoverageNotIncluded')}</span>
            </Col>
          </Row>
          ) : ''}
          {!item.terms.mandatoryCoverageNotIncluded && !item.terms.zeroExcess ? (
          <Row key='basicCoverageIncluded'>
            <Col xs={12}>
              <span className="badge badge-info basicCoverageIncluded">{t('basicCoverageIncluded')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.terms.oneWayIncluded ? (
          <Row key='oneWayIncluded'>
            <Col xs={12}>
              <span className="badge badge-info oneWayIncluded">{t('oneWayIncluded')}</span>
            </Col>
          </Row>
          ) : ''}
          {!item.terms.oneWayIncluded && item.puLocation !== item.doLocation ? (
          <Row key='checkOneWayFee'>
            <Col xs={12}>
              <span className="badge badge-danger checkOneWayFee">{t('checkOneWayFee')}</span>
            </Col>
          </Row>
          ) : ''}
        {item.terms.crossBorderIncluded ? (
          <Row key='crossBorderIncluded'>
            <Col xs={12}>
            <span className="badge badge-success crossBorderIncluded">{t('crossBorderIncluded')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.group && item.group.length > 3 && item.group[3] === "N" ? (
          <Row key='noAirCon'>
            <Col xs={12}>
              <span className="badge badge-primary">{t('noAirCon')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.spec.is4x4 ? (
          <Row key='4x4'>
            <Col xs={12}>
              <span className="badge badge-dark">{t('4x4')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.spec.isHybrid ? (
          <Row key='hybrid'>
            <Col xs={12}>
              <span className="badge badge-success">{t('hybrid')}</span>
            </Col>
          </Row>
          ) : ''}
          {item.spec.isElectric ? (
          <Row key='electric'>
            <Col xs={12}>
              <span className="badge badge-success">{t('electric')}</span>
            </Col>
          </Row>
          ) : ''}
      </Col>
    </Row>
    <Row className="w-100">
      <Col xs={6} className="payment">
        <h2 className="mb-0">{item.payment && item.payment.renderTotal && typeof item.payment.renderTotal !== 'object'
          ? item.payment.renderTotal.toLocaleString(locale, {style: 'currency', currency: item.payment.renderCurrency, maximumFractionDigits: 0, minimumFractionDigits: 0,})
          : 0
        }</h2>
        <div className="note">
          {t('priceForEntirePeriod')}&nbsp;
          {item.payment && item.payment.vatNote ? 
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-question-circle p-8 vatNote`} data-note={item.payment.vatNote} viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
          </svg>
           : ''}
           <br/>
           {t('payNowNote')} {item.payment && item.payment.renderPayNow && typeof item.payment.renderPayNow !== 'object'
              ? item.payment.renderPayNow.toLocaleString(locale, {style: 'currency', currency: item.payment.renderCurrency, maximumFractionDigits: 0, minimumFractionDigits: 0,})
              : 0
            }
        </div>
      </Col>
      <Col xs={6} className={`submit text-${rtl ? "left" : "right"}`}>
        <Button target="_blank" href={item.deeplink} size="lg" variant="success" data-supplier={item.key} onClick={trackOutbound} onAuxClick={e => e.preventDefault()} onContextMenu={e => e.preventDefault()} referrerPolicy="unsafe-url">{t('viewDeal')}</Button>
        <div className="note mt-1">
          {t('toSite')} {t(item.key)}&nbsp;
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-question-circle p-8 website" data-website={item?.key} viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
          </svg>
        </div>
      </Col>
      </Row>
  </Row>
  )
};

export default MobileItem;
