// redux/prefs/prefsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "en",
  currency: "USD",
};

const prefsSlice = createSlice({
  name: "prefs",
  initialState,
  reducers: {
    setPrefs: (state, action) => {
      // console.log(`in setPrefs: ${JSON.stringify(action)}`)
      return { ...state, ...action.payload };
    },
    updatePrefsRequest: (state, action) => {
      // Optionally mark "loading" here
    },
  },
});

export const { setPrefs, updatePrefsRequest } = prefsSlice.actions;
export default prefsSlice.reducer;
