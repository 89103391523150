// redux/prefs/prefsSaga.js
import { call, put, takeLatest } from "redux-saga/effects";
import { setPrefs } from "./prefsSlice";
import api from "../../utils/apiv2"; // your API wrapper
import { jwtDecode } from "jwt-decode";

function getToken(){
    // Get token
    const token = localStorage.getItem("token");
    if (!token) {
      // console.error("No token found");
      return;
    }
  
    // Decode token
    let userId;
    try {
      const decoded = jwtDecode(token);
      return decoded
    } catch (err) {
      console.error("Invalid token:", err);
      return;
    }  
}

function* handleUpdatePrefs(action) {
  const newPrefs = action.payload;

  // console.log(`in handleUpdatePrefs: ${JSON.stringify(newPrefs)}`);
  // 1. Update local state
  yield put(setPrefs(newPrefs));

  // 2. Call the backend
  try {
    const token = getToken();
    if (token){
      yield call(api.put, `/user/${token.id}`, {prefs: newPrefs});
    }
  } catch (err) {
    console.error("Failed to sync prefs:", err);
    // Optional: dispatch error or rollback
  }
}

export default function* prefsSaga() {
  yield takeLatest("prefs/updatePrefsRequest", handleUpdatePrefs);
}
