import React, { useState, useEffect } from "react";
import LoginModal from "../LoginModal/index.js";
import { jwtDecode } from "jwt-decode";
import GoogleOneTap from "./GoogleOneTap";
import { useSelector, useDispatch } from "react-redux";
import { setPrefs } from "../../redux/prefs/prefsSlice";
import { setCookie, getCookie } from '../../utils/cookies';
import { setUserFromToken, logoutUser } from "../../redux/user/userSlice";

const Login = ({dir, cid}) => {
  const dispatch = useDispatch();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const afterLogin = (data) => {
    const { token, prefs, lastCampaign } = data || {};
  
    dispatch(setUserFromToken(token || null));
  
    if (prefs) {
      dispatch(setPrefs(prefs));
    }
  
    if (lastCampaign?.name) {
        const attributor = getCookie("attributor") // get the attributor from cookie only and not from sessionStorage to avoid spam      const attributor = getCookie("attributor");
      if (lastCampaign.name !== attributor && lastCampaign.expiry) {
        console.log(`got the attributor ${lastCampaign.name} from backend and will replace the old one ${attributor}`);
        setCookie("attributor", lastCampaign.name, new Date(lastCampaign.expiry).toUTCString());
      }
    }
  };
  
  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser);
  //   });
  //   return () => unsubscribe();
  // }, []);

  // feature toggle
  const [isAuthEnabled, setIsAuthEnabled] = useState(true);

  return (
    isAuthEnabled ?
      <>
        <button onClick={() => setShowLoginModal(true)} type="button" className="btn btn-lg">
          {user ? (
            user.picture ? (
              <img src={user.picture} alt="Profile" className="rounded-circle" width="30" />
            ) : (
              <div className="rounded-circle text-white d-flex align-items-center justify-content-center" style={{ backgroundColor: "#87d6e4", width: "30px", height: "30px", fontSize: "20px", marginLeft: "auto", marginRight: "auto" }}>
                {user.name ? user.name.charAt(0).toUpperCase() : user.email.charAt(0).toUpperCase()}
              </div>
            )
          ) : (
            <>
              <GoogleOneTap onLogin={afterLogin} cid={cid}></GoogleOneTap>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{ fill: "#87d6e4" }} className="bi bi-person-fill" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
              </svg>
            </>
          )
          }
        </button>
        <LoginModal dir={dir} shouldShow={showLoginModal} setModalVisible={setShowLoginModal} user={user} afterLogin={afterLogin} cid={cid}></LoginModal>
      </>
      :
      <button type="button" className="btn btn-lg">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{ fill: "#87d6e4" }} className="bi bi-person-fill" viewBox="0 0 16 16">
          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
        </svg>
      </button>
  );
};

export default Login;
