import React, { useEffect } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./styles.scss";
import { setLanguage } from "../../redux/ui/action";
import { Helmet } from "react-helmet-async";
// import { RTL_DIRECTION } from "../../constants/default";
// import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { updatePrefsRequest } from "../../redux/prefs/prefsSlice";

const LanguageSwitch = ({ dispatch, /*lng*/ }) => {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const prefs = useSelector((state) => state.prefs);

  const changeLanguage = (event) => {
    // dispatch(setLanguage(event.target.value));
    i18n.changeLanguage(event.target.value);
    dispatch(updatePrefsRequest({ lang: event.target.value }));

    // persisting lang in url is done in Header (parent component)
  };

  const lng = i18n.language.substring(0, 2)
  // moved some setup logic to App.js (for cases where the header+lang switcher is missing such as the widget)


  return (
    <ButtonGroup toggle>
      <Helmet>
        <html lang={lng}></html>
      </Helmet>
      <ToggleButton
        type="radio"
        name="language"
        value={"he"}
        checked={lng === "he"}
        onChange={changeLanguage}
        variant="outline-dark"
      >
        <span className="d-md-none">ע</span>
        <span className="d-none d-md-inline">עברית</span>
      </ToggleButton>
      <ToggleButton
        type="radio"
        name="language"
        value={"en"}
        checked={lng === "en"}
        onChange={changeLanguage}
        variant="outline-dark"
      >
        <span className="d-md-none">E</span>
        <span className="d-none d-md-inline">English</span>
      </ToggleButton>
      <ToggleButton
        type="radio"
        name="language"
        value={"ar"}
        checked={lng === "ar"}
        onChange={changeLanguage}
        variant="outline-dark"
      >
        <span className="d-md-none">ع</span>
        <span className="d-none d-md-inline">عربي</span>
      </ToggleButton>
    </ButtonGroup>
  );
};

const mapStateToProps = ({ vendors, results, ui }) => ({
  // lng: ui.language,
});

export default connect(mapStateToProps)(LanguageSwitch);
