import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const initialState = {
  user: (() => {
    const token = localStorage.getItem("token");
    if (!token) return null;
    try {
      const decoded = jwtDecode(token);
      const now = Date.now() / 1000;
      if (decoded.exp && decoded.exp < now) {
        localStorage.removeItem("token");
        return null;
      }
      return decoded;
    } catch (e) {
      console.error("Invalid token", e);
      localStorage.removeItem("token");
      return null;
    }
  })(),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserFromToken: (state, action) => {
      const token = action.payload;
      if (token) {
        localStorage.setItem("token", token);
        try {
          const decoded = jwtDecode(token);
          state.user = decoded;
        } catch (e) {
          console.error("Invalid token", e);
          localStorage.removeItem("token");
          state.user = null;
        }
      } else {
        localStorage.removeItem("token");
        state.user = null;
      }
    },
    logoutUser: (state) => {
      localStorage.removeItem("token");
      state.user = null;
    },
  },
});

export const { setUserFromToken, logoutUser } = userSlice.actions;
export default userSlice.reducer;
