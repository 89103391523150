import React, { useEffect, useRef } from 'react';
import { getCookie } from '../../utils/cookies';

const GoogleOneTap = ({ onLogin, cid }) => {
  const cidRef = useRef(cid);

  // 🔁 Update the ref on every render
  useEffect(() => {
    cidRef.current = cid;
  }, [cid]);

  useEffect(() => {
    console.log("Google One Tap component mounted with cid:", cid);
    // Load the Google One Tap script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.onload = initializeOneTap;
    document.head.appendChild(script);

    return () => {
      // console.log("Google One Tap component unmounted");
      // Clean up by removing the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  const getAttributor = () => {
    const attributor = getCookie('attributor')
    const attributorExpiry = getCookie('attributor_expiry')
    return { attributor, attributorExpiry }
  }

  const initializeOneTap = () => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse, // This function will handle the login result
      auto_select: false,
    });

    // Render One Tap UI
    window.google.accounts.id.prompt();
  };


  const handleCredentialResponse = async (response) => {
    // console.log("Google One Tap Response:", response); // Debug log
    try {
      const res = await fetch(`${process.env.REACT_APP_USERS_BACKEND_URL}/api/auth/google`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: response.credential,
          attributor: getAttributor(),
          externalClientId: cidRef.current,
        }),
      });

      const data = await res.json();
      if (data) {
        onLogin(data);
      }
    } catch (error) {
      console.error("Google login failed", error);
    }
  };

  return <></>;
};

export default GoogleOneTap;
