import { useState, useEffect } from "react";
import { clarity } from "react-microsoft-clarity";
import { useSelector } from "react-redux";

const Clarity = ({ creds }) => {
  const [credentials, setCredentials] = useState(creds);
  const user = useSelector((state) => state.user.user);
  // console.log(user)

  useEffect(() => {
    if (
      window.location.hostname !== "localhost" &&
      window.location.search.indexOf("ignore=true") === -1 &&
      navigator.userAgent.indexOf("iPhone") === -1
    ) {
      clarity.init(credentials);
    }
  }, []);

  useEffect(() => {
    console.log("User changed:", user?.id);
    if (clarity.hasStarted() && user?.id) {
      clarity.identify(user.id);
    }
  }, [user]);


  return null
};

export default Clarity;
